import React, {FC} from 'react';

interface SaveButtonProps {
    onClick: () => void;
    hasChanges: boolean;
}

const SaveButton: FC<SaveButtonProps> = ({onClick, hasChanges}) => {
    return (
        <button type="button" className="btn btn-secondary gap-2 d-flex gap-between-elems" disabled={!hasChanges}
                onClick={onClick}>
            <i className="bi bi-floppy"></i>
            Enregistrer
        </button>
    )
}

export default SaveButton;