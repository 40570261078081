import React, {FC, useEffect} from "react"
import "./SecondSection.css"
import {CarouselItem} from "../CarouselItem";

const SecondSection: FC = () => {

    function pauseAllVideos(): void {
        const videos: NodeListOf<HTMLVideoElement> = document.querySelectorAll('video');
        videos.forEach(video => {
            video.pause();
        });
    }

    useEffect(() => {
        const carousel = document.getElementById("carouselVideo");
        carousel.addEventListener('slide.bs.carousel', evt => {
            pauseAllVideos();
        })
    }, []);

    return <div className="second-section">
        <h1 className="secondsection-text">Ce qu'en pensent les anciens</h1>
        <div id="carouselVideo" className="carousel slide" data-bs-ride="false" data-bs-interval="false">
            <div className="carousel-inner">
                <CarouselItem
                    url="https://iegeducation-assets.ams3.cdn.digitaloceanspaces.com/rinvindaf-temoignage-1.mp4"
                    isVideo={true} isActive={true}/>
                <CarouselItem
                    url="https://iegeducation-assets.ams3.cdn.digitaloceanspaces.com/rinvindaf-temoignage-2.mov"
                    isVideo={true}/>
                <CarouselItem
                    url="https://iegeducation-assets.ams3.cdn.digitaloceanspaces.com/rinvindaf-temoignage-3.mov"
                    isVideo={true}/>
                <CarouselItem
                    url="https://iegeducation-assets.ams3.cdn.digitaloceanspaces.com/rinvindaf-temoignage-4.mov"
                    isVideo={true}/>
                <CarouselItem
                    url="https://iegeducation-assets.ams3.cdn.digitaloceanspaces.com/rinvindaf-temoignage-5.mov"
                    isVideo={true}/>
            </div>
            <button className="carousel-control-prev button-for-mobile" type="button" data-bs-target="#carouselVideo"
                    data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Précédent</span>
            </button>
            <button className="carousel-control-next button-for-mobile" type="button" data-bs-target="#carouselVideo"
                    data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Suivant</span>
            </button>
        </div>
    </div>
}

export default SecondSection;