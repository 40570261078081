import {useMap} from "../../hooks/useMap";
import {memo, useEffect} from "react";
import {Position} from "../../interfaces/position";

interface Props {
    //place: google.maps.places.PlaceResult | null;
    place: Position
}

const MapHandler = ({place}: Props) => {
    const map = useMap();

    useEffect(() => {
        if (!map || !place) return;
        const latLng = new google.maps.LatLng(place.geo_position.latitude, place.geo_position.longitude);

        map.setCenter(latLng);
        /*if (place.geometry?.viewport) {
            const latLngBounds : google.maps.LatLng;
            lat
            map.setCenter()
            map.fitBounds(place.geometry?.viewport);
        }*/
    }, [map, place]);

    return null;
};

export default memo(MapHandler);