import React, {ReactNode} from "react";
import {useSession} from "../SessionContextProvider/SessionContextProvider";
import {Navigate, useLocation} from "react-router-dom";
import {makeRequestForStatusCode} from "../../helpers/makeRequests";

const isRefreshed = await makeRequestForStatusCode("refresh");

const ProtectedRoute: React.FC<{ children: ReactNode }> = ({children}) => {

    const location = useLocation();

    if (process.env.NODE_ENV === "production") {
        const {isAuthenticated} = useSession();
        if (!isAuthenticated) {
            if (isRefreshed !== 200) {
                return <Navigate to="/login" state={{from: location}} replace/>;
            }

        }
    }

    if (!localStorage.getItem("umami.disabled")) {
        localStorage.setItem("umami.disabled", "1");
    }
    return <>{children}</>;
}

export default ProtectedRoute;
