import {MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import {Session} from "../interfaces/session";
import {ReturnTypes} from "../interfaces/returntype";
import {makeRequest} from "../helpers/makeRequests";

export function useGetSessions(callback?: () => void): [Session[], () => void, MutableRefObject<number>] {

    const [sessions, setSessions] = useState<Session[]>([]);

    const shouldFetchSessions = useRef(0);

    const fetchSessions = useCallback(async () => {
        makeRequest("get-sessions", "", ReturnTypes.Json).then((response) => {
            if (Array.isArray(response)) {
                const [fetchedSessions] = response;
                const filteredSessions = fetchedSessions?.filter((session: Session) => session.is_active);
                setSessions(filteredSessions);
            }
            if (callback) {
                callback();
            }
        })


    }, [shouldFetchSessions.current]);

    useEffect(() => {
        fetchSessions();
    }, [fetchSessions]);

    return [sessions, fetchSessions, shouldFetchSessions];
}