// @ts-nocheck

import {useCallback, useEffect} from "react";

const useRecaptcha = () => {
    const captchaKey = process.env.REACT_APP_SITE_KEY;

    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://google.com/recaptcha/api.js?&render=${captchaKey}`;
        script.async = true;
        document.head.appendChild(script);
    }, []);

    return useCallback(async (action: string) => {
        if (window.grecaptcha) {
            return window.grecaptcha.execute(captchaKey, {action: action});
        }
    }, []);

}

export default useRecaptcha;