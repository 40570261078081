import {ReturnTypes} from "../interfaces/returntype";

const host = process.env.NODE_ENV === "production" ? window.location.host : "iegeducation.com";

export async function makeRequest(endpoint: string, body: string, type: ReturnTypes, folder?: string): Promise<[any, number] | number> {
    const folder_ = folder ?? "registrations";
    const url = `https://${host}/iegeducation-functions/${folder_}/${endpoint}`;
    try {
        const result = await fetch(url, {
            method: "POST",
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            body
        });
        const statusCode = result.status;
        switch (type) {
            case ReturnTypes.Json:
                return [await result.json(), statusCode];
            case ReturnTypes.String:
                return [await result.text(), statusCode];
            case ReturnTypes.StatusCode:
                return result.status;
        }
    } catch (exception) {
        console.error(exception);
        return 500;
    }
}

export function handleErrorCode(response: any) {
    if (Array.isArray(response)) {
        const [result, statusCode] = response;
        if (statusCode !== 200) {
            throw new Error(`${statusCode} error returned`);
        } else {
            return result;
        }
    } else {
        const statusCode = response as number;
        if (statusCode !== 200) {
            throw new Error(`${statusCode} error returned`)
        }
    }
}

export async function makeRequestTemp(endpoint: string, body: string): Promise<number> {
    const url = `https://${host}/iegeducation-functions/${endpoint}`;
    const result = await fetch(url, {
        method: "POST",
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        body
    });
    return result.status;
}

export async function makeCaptchaRequest(token: string) {
    const url = `https://${host}/iegeducation-functions/auth/validateCaptchaToken`;
    const result = await fetch(url, {
        method: "POST",
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({token: token})
    });
    return await result.json();
}


export async function makeRequestForStatusCode(endpoint: string, body?: string): Promise<number> {
    const url = `https://${host}/iegeducation-functions/auth/${endpoint}`;
    const result = await fetch(url, {
        method: "POST",
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        body
    });
    return result.status
}