import {useContext} from "react";
import {APIProviderContext, GoogleMapsContext} from "@vis.gl/react-google-maps";
import {logErrorOnce} from "../helpers/errors";

export const useMap = (id: string | null = null): google.maps.Map | null => {
    const ctx = useContext(APIProviderContext);
    const {map} = useContext(GoogleMapsContext) || {};

    if (ctx === null) {
        logErrorOnce(
            'useMap(): failed to retrieve APIProviderContext. ' +
            'Make sure that the <APIProvider> component exists and that the ' +
            'component you are calling `useMap()` from is a sibling of the ' +
            '<APIProvider>.'
        );

        return null;
    }

    const {mapInstances} = ctx;

    // if an id is specified, the corresponding map or null is returned
    if (id !== null) return mapInstances[id] || null;

    // otherwise, return the closest ancestor
    if (map) return map;

    // finally, return the default map instance
    return mapInstances['default'] || null;
};
