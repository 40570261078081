import {Header} from "../../components/Header";
import {Form} from "../../components/Form";
import React, {FC} from "react";
import {Footer} from "../../components/Footer";

const FormulaireInscription: FC = () => {
    return (
        <div style={{width: "100%"}}>
            <Header currentPage="inscriptions"/>
            <Form/>
            <Footer/>
        </div>

    )
}

export default FormulaireInscription;