import {DateTime} from 'luxon';

const nameRegex = /^[a-zA-Z]+[-]*[a-zA-Z]*$/;
const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/;
const phoneRegex = /^(\+[0-9]{10,})|(00[0-9]{10,})$/
const addressRegex = /^[\p{L}\p{N} ,\(\)]+$/u;

export function isValidName(name: string): boolean {
    if (name.match(nameRegex) == null) {
        return false;
    }
    return true;
}

export function formatPrice(price: number): string {
    return new Intl.NumberFormat('fr-FR', {maximumSignificantDigits: 6}).format(
        price,
    )
}

export function unFormatPrice(stringNumber: string): number {
    try {
        const thousandSeparator = Intl.NumberFormat('fr-FR').format(11111).replace(/\p{Number}/gu, '');
        const decimalSeparator = Intl.NumberFormat('fr-FR').format(1.1).replace(/\p{Number}/gu, '');

        return parseFloat(stringNumber
            .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
            .replace(new RegExp('\\' + decimalSeparator), '.')
        );
    } catch (e) {
        return 0;
    }
}

export function getNumberFromPrice(stringNumber: string): number {
    const regex = /\d[\d\s ]*/g;
    const match = stringNumber.match(regex);

    if (match) {
        return Number(match[0].replace(/\s| /g, '')); // Remove any spaces or non-breaking spaces
    }
    return 0;
}


export function convertEURtoXAF(price: number): number {
    return Math.ceil(price * 656.66);
}

export function convertXAFtoEUR(price: number): number {
    return Math.ceil(price / 656.66);
}

export function isValidEmail(email: string): boolean {
    if (email.length > 256 || email.match(emailRegex) == null) {
        return false;
    }
    return true;
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
    if (phoneNumber.length > 30 || phoneNumber.match(phoneRegex) == null) {
        return false
    }
    return true;
}

export function isValidAddress(address: string): boolean {
    if (address.length > 200 || address.match(addressRegex) == null) {
        return false
    }
    return true;
}

export function isAtLeast18YearsOld(dateString: string): boolean {
    // Parse the date string into a Date object
    const birthDate = new Date(dateString);

    // Get today's date
    const today = new Date();

    // Calculate the difference in years
    let age = today.getFullYear() - birthDate.getFullYear();

    // Adjust if the birthday has not occurred yet this year
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    // Check if the age is at least 18
    return age >= 16;
}

export function localiseDateString(dateString: string, withTime = false): string {
    const dt = DateTime.fromISO(dateString, {locale: "fr"});
    if (withTime) {
        return dt.toFormat('d MMMM yyyy HH:mm');
    }
    return dt.toFormat('d MMMM yyyy');

}

export function formatDate(startDate: Date, endDate: Date): string {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const startDay = formatDay(start.getDate());
    const endDay = formatDay(end.getDate());

    if (start.getMonth() === end.getMonth()) {
        return `du ${startDay} au ${endDay} ${formatMonth(start)} ${start.getFullYear()}`;
    }
    return `du ${startDay} ${start.getMonth()} au ${endDay} ${formatMonth(end)} ${end.getFullYear()}`
}

function formatMonth(date: Date): string {
    const formatter = new Intl.DateTimeFormat('fr', {month: 'long'});
    return formatter.format(date);
}

function formatDay(day: number): string {
    if (day === 1) {
        return "1er";
    }
    return day.toString();
}