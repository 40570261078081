// @ts-nocheck
import {BrowserRouter} from "react-router-dom";
import "./App.css";
import {PageHandler} from "./routing/page-handler";
import {APIProvider} from '@vis.gl/react-google-maps';
import React, {useEffect} from "react";

export default function App() {
    const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY ?? "";

    useEffect(() => {
        onScrollToBottom(() => {
            try {
                umami.track("Scrolled to bottom");
            } catch (e) {
                console.error(e);
            }
        });
    }, []);

    const onScrollToBottom = (callback: () => void) => {
        let triggered = false;
        window.addEventListener("scroll", () => {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                if (!triggered) {
                    callback();
                    triggered = true;
                }
            }
        });
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }

    return (
        <div className="App">
            <BrowserRouter basename="/">
                <APIProvider apiKey={googleMapsKey}>
                    <PageHandler/>
                </APIProvider>
            </BrowserRouter>
        </div>
    );
}