import {ReturnTypes} from "../interfaces/returntype";
import {handleErrorCode, makeRequest} from "./makeRequests";
import {SessionCount} from "../interfaces/session";

export const getNbrInscrits = async (id: number) => {
    const count = await makeRequest("get-count-session", JSON.stringify({"sessionId": id}), ReturnTypes.String);
    if (Array.isArray(count)) {
        return count[0];
    }
    return 0;
}

export const getNbrInscritsMultiple = async (ids: number[]) => {
    try {
        const result = await makeRequest("get-count-sessions", JSON.stringify({"session_ids": ids}), ReturnTypes.Json);
        const counts: SessionCount[] = handleErrorCode(result).counts;
        return counts;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}