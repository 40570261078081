import React, {FC} from "react"
import "./FirstSection.css"

const FirstSection: FC = () => {
    return <div className="first-section">
        <h1 className="firstsection-text">3 jours de formation pour changer l'Afrique.</h1>
        <div>
        </div>
        <div>Le RINVINDAF, "Réinventons les Industriels Africains", est une formation en géostratégie pour faire de vous
            des industriels.<br/>En 3 jours seulement, même si vous n'avez aucun diplôme, nous allons vous transformer
            en ceux qui recruteront les Bac + 3, +4, +5 , +10, +12, parce que ce diplôme d'aujourd'hui vaut plus que
            tous les diplômes que le système vous a donnés jusqu’à maintenant. <br/>
            Car tous ces diplômes, sans aucune exception, font de vous des esclaves, des salariés.
        </div>
    </div>
}

export default FirstSection;