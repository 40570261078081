import React, {FC, useEffect, useState} from "react";
import {formatPrice} from "../../helpers/processInput";

interface EditableRowProps {
    id: string;
    value: number | string;
    isEditMode: boolean;
    onChange: (value: string) => void;
}

const EditableRow: FC<EditableRowProps> = (props: EditableRowProps) => {
    const formatNotApplicable = (fare: number | string): string => {
        if (typeof fare === "number") {
            return fare === 0 ? "N/A" : formatPrice(fare)
        } else {
            return fare;
        }

    }
    const [formattedPrice, setFormattedPrice] = useState<string>(formatNotApplicable(props.value));

    useEffect(() => {
        setFormattedPrice(formatNotApplicable(props.value));
    }, [props.value]);

    return (
        <td>
            {props.isEditMode ?
                <div style={{display: "flex", flexDirection: "column"}}>
                    <input id={props.id} type="text" value={formattedPrice}
                           onChange={(event) => setFormattedPrice(event.target.value)} onBlur={(event) => {
                        props.onChange ? props.onChange(event.target.value) : () => null;
                    }}/>
                    <small id={`${props.id}-small`} className="text-muted"></small>
                </div>
                : formattedPrice}
        </td>
    )
}

export default EditableRow;