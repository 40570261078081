import React, {FC, useEffect, useRef, useState} from "react";
import {useMapsLibrary} from "@vis.gl/react-google-maps";

interface AddressAutocompleteBarProps {
    onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

const AddressAutocompleteBar: FC<AddressAutocompleteBarProps> = ({onPlaceSelect}) => {

    const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const places = useMapsLibrary("places");

    useEffect(() => {
        if (!places || !inputRef.current) return;

        const options = {
            fields: ["geometry", "name", "formatted_address"]
        }
        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);

    useEffect(() => {
        if (!placeAutocomplete) return;

        placeAutocomplete.addListener("place_changed", () => {
            const place = placeAutocomplete.getPlace();
            console.log("new place", place);
            onPlaceSelect(placeAutocomplete.getPlace());
        })
    }, [onPlaceSelect, placeAutocomplete]);

    /* async function getAutoCompleteResults(text: string) {
         const url = "https://iegeducation.com/iegeducation-functions/helpers/autocomplete";
         const result = await fetch(url, {
             method: "POST",
             headers: {
                 'Content-Type': 'application/json',
             },
             body: JSON.stringify({text: text}),
         }).then((res) => res.json()).catch((err) => console.error(err));
         //console.log(result);
         setSuggestions(result.predictions.map((prediction) => prediction.description));
     }
 
     useEffect(() => {
         console.log("suggestions", suggestions);
     }, [suggestions])*/

    return (
        <div className="form-group">
            <label>Addresse</label>
            <input type="text" className="form-control" ref={inputRef}></input>
        </div>
    )
}

export default AddressAutocompleteBar;