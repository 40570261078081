// src/helpers/handleSubmit.ts
import {FormEvent} from 'react';
import {useNavigate} from 'react-router-dom';
import {Captcha} from "../interfaces/captcha";
import {handleErrorCode, makeCaptchaRequest, makeRequest} from "./makeRequests";
import {ReturnTypes} from "../interfaces/returntype";
import {FormEntry} from "../interfaces/form";

export const submitForm = async (
    event: FormEvent<HTMLFormElement>,
    runRecaptchaChallenge: (action: string) => Promise<string>,
    formData: FormEntry,
    navigate: ReturnType<typeof useNavigate>,
    setIsLoading: (loading: boolean) => void,
    setIsDanger: (danger: boolean) => void
) => {
    event.preventDefault();
    setIsLoading(true);
    try {
        const captchaToken = await runRecaptchaChallenge("inscription");
        const form: HTMLFormElement = document.getElementById("formy") as HTMLFormElement;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            form.classList.add("was-validated");
            setIsLoading(false);
        } else if (captchaToken && captchaToken !== "") {
            const tokenValidation: Captcha = await makeCaptchaRequest(captchaToken);
            if (tokenValidation.score >= 0.5) {
                try {
                    const response = await makeRequest("add-registration", JSON.stringify(formData), ReturnTypes.String);
                    handleErrorCode(response);
                    navigate("/success", {replace: true});
                } catch (e) {
                    console.error(e);
                    setIsDanger(true);
                }
            }
            setIsLoading(false);
            setIsDanger(true);
        } else {
            setIsLoading(false);
            setIsDanger(true);
        }
    } catch (error) {
        console.error(error);
        setIsLoading(false);
        setIsDanger(true);
    }
};