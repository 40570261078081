import React, {FC, FormEvent, useEffect, useState} from 'react';
import "./Form.css";
import {formatDate} from '../../helpers/processInput';
import {useGetSessions} from '../../hooks/useGetSessions';
import {Session} from '../../interfaces/session';
import {NationalityPicker} from '../NationalityPicker';
import {CountryPicker} from '../CountryPicker';
import useRecaptcha from "../../hooks/useRecaptcha";
import {useNavigate} from 'react-router-dom';
import {useGetPrices} from "../../hooks/useGetPrices";
import {FormEntry} from "../../interfaces/form";
import {submitForm} from "../../helpers/submitForm";
import {get16YearsOldDate} from "../../helpers/dates";

const Form: FC = () => {
    const runRecaptchaChallenge = useRecaptcha();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [civility, setCivility] = useState<string>("Mr");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [birthDate, setBirthDate] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [addressLine2, setAddressLine2] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [country, setCountry] = useState<string>("Cameroun");
    const [postalCode, setPostalCode] = useState<string>("");
    const [paymentForm, setPaymentForm] = useState<string>("Paiement en intégralité");
    const [profession, setProfession] = useState<string>("Agriculture");
    const [nationality, setNationality] = useState<string>("Camerounaise");
    const [notes, setNotes] = useState<string>("");
    const [session, setSession] = useState<Session | null>(null);
    const [sessions] = useGetSessions();
    const [isDanger, setIsDanger] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fares, paymentMethods] = useGetPrices();


    useEffect(() => {
        // Calculate yesterday's date
        const maxDate = get16YearsOldDate();
        const element: HTMLInputElement = document.getElementById("birthDate") as HTMLInputElement
        element.max = maxDate;
    }, []);

    useEffect(() => {
        handleEmptySessions();
    }, [sessions]);

    useEffect(() => {
        const dropdownList: HTMLSelectElement = document.getElementById("paymentForm") as HTMLSelectElement;
        const defaultOption: HTMLOptionElement = document.createElement("option");
        if (paymentMethods.length == 0) {
            dropdownList.innerHTML = '';
            defaultOption.value = "default";
            defaultOption.text = "N/A";
            dropdownList.appendChild(defaultOption);
            dropdownList.disabled = true;
        } else {
            setPaymentForm(paymentMethods[0].payment_option);
            const options = dropdownList.getElementsByTagName("OPTION");
            for (let i = 0; i < options.length; i++) {
                if (options[i].innerHTML == "N/A") {
                    dropdownList.removeChild(options[i]);
                }
            }
            dropdownList.disabled = false;
        }
    }, [paymentMethods]);

    const handleEmptySessions = () => {
        const dropdownList: HTMLSelectElement = document.getElementById("inputSession") as HTMLSelectElement;
        const submitButton: HTMLButtonElement = document.getElementById("submit") as HTMLButtonElement;
        const defaultOption: HTMLOptionElement = document.createElement("option");

        if (sessions.length == 0) {
            dropdownList.innerHTML = '';
            defaultOption.value = "default";
            defaultOption.text = "Aucune formation disponible";
            dropdownList.appendChild(defaultOption);
            dropdownList.disabled = true;
            submitButton.disabled = true;
        } else {
            const filteredSession = sessions.filter((session) => session.is_active);
            setSession(filteredSession[0]);
            const options = dropdownList.getElementsByTagName("OPTION");
            for (let i = 0; i < options.length; i++) {
                if (options[i].innerHTML == "Aucune formation disponible") {
                    dropdownList.removeChild(options[i]);
                }
            }
            dropdownList.disabled = false;
            submitButton.disabled = false;
        }
    }


    const processSession = () => {
        const element: HTMLSelectElement = document.getElementById("inputSession") as HTMLSelectElement;
        const selectedSession: Session = sessions.find((session) => session.id.toString() == element?.value) as Session;
        setSession(selectedSession);
    }

    const processBirthDate = () => {
        const element: HTMLInputElement = document.getElementById("birthDate") as HTMLInputElement;
        const text = element?.value;
        const birthDate: Date = new Date(text);
        const maxDate = new Date(get16YearsOldDate());
        if (birthDate > maxDate) {
            element.classList.add("is-invalid");
        } else {
            element.classList.remove("is-invalid");
            setBirthDate(text);
        }
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        const formEntry: FormEntry = {
            "name": lastName,
            "firstName": firstName,
            "email": email,
            "civility": civility,
            "phone": phoneNumber,
            "birthDate": birthDate,
            "sessionId": String(session?.id),
            "address": address,
            "addressLine2": addressLine2,
            "city": city,
            "country": country,
            "postalCode": postalCode,
            "jobArea": profession,
            "paymentMethod": paymentForm,
            "notes": notes,
            "nationality": nationality,
        };
        submitForm(event, runRecaptchaChallenge, formEntry, navigate, setIsLoading, setIsDanger);
    }

    return (
        <div className='container'>
            <h1 style={{marginBottom: "calc(5vh)"}}>Inscription à une formation RINVINDAF</h1>
            <form className="form needs-validation" id="formy" data-testid="form" onSubmit={handleSubmit} noValidate>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="name" className="form-label">Nom</label>
                        <input type="text" className="form-control" id="name" aria-describedby="nameHelp"
                               onChange={(e) => setLastName(e.target.value)}
                               pattern="^[A-Za-zÀ-ÖØ-öø-ÿÇÉéÈèÊêËëÎîÏïÔôÙùÛûÜüŸÿç' \-]{1,255}$"
                               required/>
                        <div className="invalid-feedback">
                            Le nom est incorrect.
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="firstName" className="form-label">Prénom</label>
                        <input type="text" className="form-control" id="firstName" aria-describedby="firstNameHelp"
                               onChange={(e) => setFirstName(e.target.value)}
                               pattern="^[A-Za-zÀ-ÖØ-öø-ÿÇÉéÈèÊêËëÎîÏïÔôÙùÛûÜüŸÿç' \-]{1,255}$"
                               required/>
                        <div className="invalid-feedback">
                            Le prénom est incorrect.
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="genre">Civilité</label>
                    <select className="form-control" id="genre"
                            onChange={(e) => setCivility((e.target as HTMLSelectElement).value)}>
                        <option>Mr</option>
                        <option>Mme</option>
                        <option>Neutre</option>
                    </select>
                </div>

                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Adresse e-mail</label>
                    <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                           onChange={(e) => setEmail(e.target.value)} required/>
                    <div className="invalid-feedback">
                        Veuillez fournir une adresse e-mail valide.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Numéro de téléphone (avec préfixe
                        régional)</label>
                    <input type="tel" className="form-control" id="phone" aria-describedby="phoneHelp"
                           autoComplete="tel"
                           onChange={(e) => setPhoneNumber(e.target.value)} required/>
                    <div className="invalid-feedback">
                        Veuillez fournir un numéro de téléphone valide.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="birthDate" className="form-label">Date de naissance</label>
                    <input type="date" className="form-control" id="birthDate" min={"1900-01-01"}
                           aria-describedby="birthDateHelp" onChange={processBirthDate} required/>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="profession" className="form-label">Profession</label>
                        <select className="form-control" name="zone_activite" id="profession"
                                onChange={(event) => setProfession(event.target.value)}>
                            <option value="agriculture">Agriculture</option>
                            <option value="agroalimentaire">Agroalimentaire</option>
                            <option value="Art et Culture">Art et Culture</option>
                            <option value="Automobile">Automobile</option>
                            <option value="Banque et Assurance">Banque et Assurance</option>
                            <option value="Bâtiment et Travaux Publics">Bâtiment et Travaux Publics</option>
                            <option value="Commerce">Commerce</option>
                            <option value="Communication">Communication</option>
                            <option value="Construction">Construction</option>
                            <option value="Conseil et Consulting">Conseil et Consulting</option>
                            <option value="Défense et Sécurité">Défense et Sécurité</option>
                            <option value="Droit">Droit</option>
                            <option value="Éducation">Éducation</option>
                            <option value="Énergie">Énergie</option>
                            <option value="Environnement">Environnement</option>
                            <option value="Finance">Finance</option>
                            <option value="Hôtellerie et Tourisme">Hôtellerie et Tourisme</option>
                            <option value="Informatique">Informatique</option>
                            <option value="Ingénierie">Ingénierie</option>
                            <option value="Logistique et Transport">Logistique et Transport</option>
                            <option value="Médias">Médias</option>
                            <option value="Recherche et Développement">Recherche et Développement</option>
                            <option value="Restauration">Restauration</option>
                            <option value="Santé">Santé</option>
                            <option value="Services">Services</option>
                        </select>
                    </div>
                    <NationalityPicker bootstrapClass={"col-md-6"} setNationality={setNationality}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="inputSession">Formation choisie</label>
                    <select id="inputSession" className="form-control" onChange={processSession}>
                        {sessions && sessions.length > 0 && sessions.map((session, index) => (
                            session.is_active && <option key={index} data-testid={index}
                                                         value={session.id}>{session.place} - {formatDate(session.date_start, session.date_end)} </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="address">Addresse</label>
                    <input type="text" className="form-control" id="address" placeholder="9, Rue de la Paix"
                           onChange={(e) => setAddress(e.target.value)} required/>
                    <div className="invalid-feedback">
                        L'adresse indiquée n'est pas valide.
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="address2">Address Ligne 2</label>
                    <input type="text" className="form-control" id="address2" placeholder="Appartement, bâtiment, étage"
                           onChange={(e) => setAddressLine2(e.target.value)}/>
                    <div className="invalid-feedback">
                        L'adresse indiquée n'est pas valide.
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="city">Ville</label>
                        <input type="text" className="form-control" id="city" onChange={(e) => setCity(e.target.value)}
                               required/>
                        <div className="invalid-feedback">
                            La ville indiquée n'est pas valide.
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="inputState">Pays</label>
                        <CountryPicker setCountry={setCountry}/>
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="postalCode">Code postal</label>
                        <input type="text" className="form-control" id="postalCode" autoComplete="postal-code"
                               pattern="^[A-Za-z0-9 ]{1,100}$" onChange={(e) => setPostalCode(e.target.value)}/>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="paymentForm">Moyen de paiement</label>
                    <select className="form-control" id="paymentForm" onChange={(e) => {
                        setPaymentForm((e.target as HTMLSelectElement).value)
                    }}>
                        {paymentMethods && paymentMethods.length > 0 && paymentMethods.map((paymentMethod, index) => (
                            <option key={index}
                                    value={paymentMethod.payment_option}>{paymentMethod.payment_option}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="notes-text" className="form-label">Avez-vous des remarques
                        particulières à faire?</label>
                    <textarea className="form-control" id="notes-text" rows={3}
                              onBlur={(e) => setNotes((e.target as HTMLTextAreaElement).value)}></textarea>
                </div>
                <button style={{marginBottom: "10px"}} id="submit" type="submit"
                        className="btn btn-primary" data-umami-event="Inscription">S'inscrire
                </button>
                <div style={{flexDirection: "row", gap: "1rem"}}>
                    <div className="alert alert-danger loading-spinner" role="alert" hidden={!isDanger}>
                        Une erreur s'est produite pendant l'envoi du formulaire. Veuillez réessayer ultérieurement.
                    </div>
                    <div id="loading" className="spinner-border spinner-bis loading-spinner" role="status"
                         hidden={!isLoading}/>
                </div>
            </form>
        </div>
    )
}


export default Form;