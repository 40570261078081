import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import reportWebVitals from "./reportWebVitals";
import {Metric} from "web-vitals";
import {makeRequestTemp} from "./helpers/makeRequests";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

const uploadAnalytics = (metric: Metric) => {
    const body = JSON.stringify(metric);
    try {
        if (navigator.sendBeacon) {
            navigator.sendBeacon("https://iegeducation.com/iegeducation-functions/helpers/report-web-vitals", body);
        } else {
            makeRequestTemp("helpers/report-web-vitals", body).then(r => console.log(r));
        }
    } catch (e) {
        console.error(e);
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(uploadAnalytics);
