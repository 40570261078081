import React, {FC, useState} from "react";
import {Sidebar} from "../../components/Sidebar";
import "./Tariffs.css"
import {Fare} from "../../interfaces/fare";
import {makeRequest} from "../../helpers/makeRequests";
import {ReturnTypes} from "../../interfaces/returntype";
import EditableRow from "../../components/EditableRow";
import {
    convertEURtoXAF,
    convertXAFtoEUR,
    formatPrice,
    getNumberFromPrice,
    unFormatPrice
} from "../../helpers/processInput";
import {useGetPrices} from "../../hooks/useGetPrices";
import {PaymentMethodsTable} from "../../components/PaymentMethodsTable";

const Tariffs: FC = () => {
    const [fare, paymentMethods] = useGetPrices();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isFailure, setIsFailure] = useState<boolean>(false);

    const onChangePrice = (value: string, currency: string, targetId: string) => {
        setHasChanges(true);
        try {
            const priceAsNumber = Number(unFormatPrice(value));
            const convertedPrice = currency == "EUR" ? convertEURtoXAF(priceAsNumber) : convertXAFtoEUR(priceAsNumber);
            const hintText = document.getElementById(targetId + "-small") as HTMLElement;
            hintText.innerText = "Prix converti: " + formatPrice(convertedPrice);
            clearOtherHints(targetId + "-small");
        } catch (exception) {
            console.error(exception);
        }
    }

    const clearOtherHints = (textId: string) => {
        const allHintTexts = document.getElementsByTagName("small")
        for (const element of allHintTexts) {
            const hintText = element as HTMLElement;
            if (hintText.id !== textId) {
                hintText.innerText = "";
            }
        }
    }

    const updateFare = (): Fare => {

        const standard_fare = {
            xaf: getNumberFromPrice((document.getElementById("standard-xaf") as HTMLInputElement).value),
            eur: getNumberFromPrice((document.getElementById("standard-eur") as HTMLInputElement).value)
        }
        const reduced_fare = {
            xaf: getNumberFromPrice((document.getElementById("reduced-xaf") as HTMLInputElement).value),
            eur: getNumberFromPrice((document.getElementById("reduced-eur") as HTMLInputElement).value)
        }
        return {creation_time_stamp: fare.creation_time_stamp, standard_fare, reduced_fare, is_active: true};
    }

    const uploadChanges = async () => {
        const newFare = updateFare();
        setIsLoading(true);
        try {
            const statusCode = await makeRequest("set-fares", JSON.stringify(newFare), ReturnTypes.StatusCode);
            if (statusCode === 200) {
                setHasChanges(false);
                setIsEditing(false);
                setIsSuccess(true);
                useGetPrices();
            } else {
                setIsFailure(true);
            }
        } catch (e) {
            console.error(e);
            setIsFailure(true);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="container-fluid container-bis" style={{overflowY: "hidden"}}>
            <div className="row flex-nowrap main-row">
                <Sidebar/>
                <div className="content-container">
                    <h1 className="title">Tarifs</h1>
                    <table id="title" className="table table-striped table-responsive table-bis"
                           style={{overflowX: "scroll", width: "calc(65vw)"}}>
                        <thead>
                        <tr>
                            <th scope="col">Devise</th>
                            <th scope="col">Tarif standard</th>
                            <th scope="col">Tarif réduit (le cas échéant)</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>

                        {fare &&
                            <tbody>
                            <tr key="0">
                                <th scope="row">EUR</th>
                                <EditableRow id={"standard-eur"} value={fare.standard_fare.eur} isEditMode={isEditing}
                                             onChange={(value) => onChangePrice(value, "EUR", "standard-xaf")}/>
                                <EditableRow id={"reduced-eur"} value={fare.reduced_fare.eur} isEditMode={isEditing}
                                             onChange={(value) => onChangePrice(value, "EUR", "reduced-xaf")}/>
                                <td>
                                    <button type="button" className="btn btn-outline-secondary"
                                            onClick={() => setIsEditing((prevState) => !prevState)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z"/>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                            <tr key="1">
                                <th scope="row">XAF</th>
                                <EditableRow id={"standard-xaf"} value={fare.standard_fare.xaf} isEditMode={isEditing}
                                             onChange={(value) => onChangePrice(value, "XAF", "standard-eur")}/>
                                <EditableRow id={"reduced-xaf"} value={fare.reduced_fare.xaf} isEditMode={isEditing}
                                             onChange={(value) => onChangePrice(value, "XAF", "reduced-eur")}/>
                                <td>
                                    <button type="button" className="btn btn-outline-secondary"
                                            onClick={() => setIsEditing((prevState) => !prevState)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z"/>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        }
                    </table>
                    <div style={{marginBottom: "10px", display: "flex", gap: "0.5rem"}}>
                        <button type="button" className="btn btn-secondary gap-2 d-flex" disabled={!hasChanges}
                                onClick={uploadChanges}>
                            <i className="bi bi-floppy"></i>
                            Enregistrer
                        </button>
                        <div id="loading" className="spinner-border spinner-bis" role="status" hidden={!isLoading}/>
                    </div>
                    <div className="alert alert-success" style={{width: "calc(65vw)", marginTop: "10px"}} role="alert"
                         hidden={!isSuccess}>
                        Mise à jour effectuée avec succès.
                    </div>
                    <div className="alert alert-danger" style={{width: "calc(55vw)", marginTop: "10px"}} role="alert"
                         hidden={!isFailure}>
                        Une erreur est survenue. Veuillez réessayer ultérieurement.
                    </div>
                    <h1 className="title">Modes de paiement</h1>
                    <PaymentMethodsTable paymentMethods={paymentMethods}/>
                </div>
            </div>
        </div>
    )
}

export default Tariffs;