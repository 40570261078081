import {useEffect, useState} from "react";
import {Fare, PaymentMethod} from "../interfaces/fare";
import {makeRequest} from "../helpers/makeRequests";
import {ReturnTypes} from "../interfaces/returntype";

export function useGetPrices(): [Fare, PaymentMethod[]] {
    const defaultFare: Fare = {
        standard_fare: {xaf: 0, eur: 0},
        reduced_fare: {xaf: 0, eur: 0},
        is_active: false,
    };
    const [fare, setFare] = useState<Fare>(defaultFare);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

    const fetchFares = async () => {
        const response = await makeRequest("get-fares", "", ReturnTypes.Json);
        if (Array.isArray(response)) {
            const [fetchedFaresAndPaymentMethods, statusCode] = response;
            if (statusCode != 200) {
                throw new Error("exception occurred");
            }
            const fetchedFares = fetchedFaresAndPaymentMethods.fares;
            const fetchedPaymentMethods = fetchedFaresAndPaymentMethods.payment_methods.filter((paymentMethod: PaymentMethod) => paymentMethod.is_active);
            const fetchedFare = fetchedFares.filter((fare: Fare) => fare.is_active)[0];
            setFare(fetchedFare);
            setPaymentMethods(fetchedPaymentMethods);
        } else {
            throw new Error("exception occurred");
        }
    }

    useEffect(() => {
        fetchFares();
    }, []);

    return [fare, paymentMethods];
}