import React, {FC, useEffect, useState} from "react";
import {PaymentMethod} from "../../interfaces/fare";
import {localiseDateString} from "../../helpers/processInput";
import "./PaymentMethods.css";
import {makeRequest} from "../../helpers/makeRequests";
import {ReturnTypes} from "../../interfaces/returntype";
import EditableRow from "../EditableRow";

interface PaymentMethodsTableProps {
    paymentMethods: PaymentMethod[];
}

const PaymentMethodsTable: FC<PaymentMethodsTableProps> = ({paymentMethods}) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isDanger, setIsDanger] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);


    useEffect(() => {
        console.log("PaymentMethodsTable", paymentMethods[0]);
    }, [paymentMethods]);

    const handleDelete = async (paymentMethod: PaymentMethod) => {
        setIsLoading(true);
        try {
            const statusCode = await makeRequest("delete-payment-method", JSON.stringify(paymentMethod), ReturnTypes.StatusCode);
            if (statusCode === 200) {
                setIsSuccess(true);
            } else {
                setIsDanger(true);
                return;
            }
        } catch (e) {
            console.error(e);
            setIsDanger(true);
        } finally {
            setIsLoading(false);
        }
    }

    const createField = () => {

    }
    return (
        <div>


            <table id="table" className="table table-striped table-responsive table-bis">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Description</th>
                    <th scope="col">Date de création</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                {paymentMethods.length > 0 && (paymentMethods.map((paymentMethod, index) => (
                    <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <EditableRow id={"payment_option"} value={paymentMethod.payment_option} isEditMode={isEditing}
                                     onChange={() => console.log("hey")}/>
                        <EditableRow id={"payment_option_description"} value={paymentMethod.payment_option_description}
                                     isEditMode={isEditing} onChange={() => console.log("hey")}/>
                        <td>{localiseDateString((paymentMethod.creation_timestamp.toString()) as string)}</td>
                        <td>
                            <div style={{display: "flex", gap: "1rem"}}>
                                <button type="button" className="btn btn-outline-secondary"
                                        onClick={() => setIsEditing((prevState) => !prevState)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z"/>
                                    </svg>
                                </button>
                                <button type="button" className="btn btn-outline-danger gap-2 d-flex" onClick={() => {
                                    setIsLoading(true);
                                    handleDelete(paymentMethod);
                                    setIsLoading(false);
                                }}>
                                    <i className="bi bi-dash-circle"></i>
                                    Supprimer
                                </button>
                                <div id="loading" className="spinner-border spinner-bis" role="status"
                                     hidden={!isLoading}/>
                            </div>

                        </td>
                    </tr>
                )))}
                </tbody>
            </table>
            <div className="alert alert-danger table-bis" role="alert" hidden={!isDanger}>
                Impossible de compléter cette requête pour le moment.
            </div>
            <div className="alert alert-success table-bis" role="alert"
                 hidden={!isSuccess}>
                Mise à jour effectuée avec succès.
            </div>
            <div style={{marginBottom: "10px", display: "flex", gap: "1rem"}}>
                <button type="button" className="btn btn-primary gap-2 d-flex" onClick={createField}>
                    <i className="bi bi-plus-circle"></i>
                    Créer
                </button>
            </div>
        </div>
    )
}

export default PaymentMethodsTable;