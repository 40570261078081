import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {Session, SessionCount} from "../../interfaces/session";
import {localiseDateString} from "../../helpers/processInput";
import {getNbrInscritsMultiple} from "../../helpers/getInscrits";
import {Row} from "../../interfaces/row";
import {ExpandableRow} from "../ExpandableRow";

interface SessionsProps {
    sessions: Session[];
    handleDelete: (key: number) => void;
    handleCreate: (place: string, nickname: string, dateStart: Date, dateEnd: Date) => void;
    rows: Row[];
}

const SessionsTable: FC<SessionsProps> = ({sessions, handleDelete, handleCreate, rows}) => {

    const [nbInscrits, setNbInscrits] = useState(new Map());
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [place, setPlace] = useState<string>("");
    const [nickname, setNickname] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (sessions.length > 0) {

            const getSession = async () => {
                const counts: SessionCount[] = await getNbrInscritsMultiple(sessions.map(session => session.id));
                counts.map((count) => {
                    setNbInscrits((prevMap) => {
                        const newMap = new Map(prevMap);
                        newMap.set(count.session_id, count.count);
                        return newMap;
                    });
                });
            }
            getSession();
        }

    }, [sessions]);

    const handleStartDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setStartDate(new Date(event.target.value));

    }

    const handleEndDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEndDate(new Date(event.target.value));
    }

    const handlePlaceChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPlace(event.target.value);
    }

    const handleNicknameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNickname(event.target.value);
    }

    const callbackCreate = (place: string, nickname: string, dateStart: Date, dateEnd: Date) => {
        setIsLoading(true);
        handleCreate(place, nickname, dateStart, dateEnd);
        setIsLoading(false);
    }

    return (
        <table id="table" className="table table-striped table-responsive table-bis" style={{overflowX: "scroll"}}>
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Lieu</th>
                <th scope="col">Surnom</th>
                <th scope="col">Date de début</th>
                <th scope="col">Date de fin</th>
                <th scope="col">Nombre d'inscrits</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            {sessions.length > 0 && sessions.map((session, index) => (
                <>
                    <tr key={index}>
                        <th scope="row">
                            <button type="button" className="btn btn-outline-secondary" data-bs-toggle="collapse"
                                    data-bs-target=".multi-collapse1" aria-expanded="false"
                                    aria-controls="collapseExample">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-pencil" viewBox="0 0 16 16">
                                    <path
                                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"></path>
                                </svg>
                                <span className="visually-hidden">Button</span>
                            </button>
                        </th>
                        <td>{session.place}</td>
                        <td>{session.nickname}</td>
                        <td>{localiseDateString(new Date(session.date_start).toISOString())}</td>
                        <td>{localiseDateString(new Date(session.date_end).toISOString())}</td>
                        <td>{nbInscrits.get(session.id)}</td>
                        <td>
                            <div style={{display: "flex", gap: "1rem"}}>
                                <button type="button" className="btn btn-outline-danger gap-2 d-flex" onClick={() => {
                                    setIsLoading(true);
                                    handleDelete(index);
                                    setIsLoading(false);
                                }}>
                                    <i className="bi bi-dash-circle"></i>
                                    Supprimer
                                </button>
                                <div id="loading" className="spinner-border spinner-bis" role="status"
                                     hidden={!isLoading}/>
                            </div>
                        </td>
                    </tr>
                    <tr className="collapse multi-collapse1" id="collapseExample">
                        <td colSpan={7}>
                            <ExpandableRow sessionId={Number(session.id)}/>
                        </td>

                    </tr>
                </>

            ))}
            {rows.length > 0 && (rows.map((row, index) => (
                <tr key={row.id}>
                    <th scope="row"></th>
                    <td><input type="text" id={row.placeFieldId} aria-describedby="placeHelp"
                               onChange={handlePlaceChange} required/></td>
                    <td><input type="text" id={row.nicknameFieldId} aria-describedby="placeHelp"
                               onChange={handleNicknameChange} required/></td>
                    <td><input type="date" id={row.dateStartFieldId} aria-describedby="placeHelp"
                               min={new Date().toISOString().split('T')[0]} onChange={handleStartDateChange} required/>
                    </td>
                    <td><input type="date" id={row.dateEndFieldId} aria-describedby="placeHelp"
                               min={startDate.toISOString().split('T')[0]} onChange={handleEndDateChange} required/>
                    </td>
                    <td>0</td>
                    <td>
                        <div style={{display: "flex", gap: "1rem"}}>
                            <button type="button" className="btn btn-success gap-2 d-flex"
                                    onClick={() => callbackCreate(place, nickname, startDate, endDate)}>
                                <i className="bi bi-plus-circle"></i>
                                Ajouter
                            </button>
                            <div id="loading" className="spinner-border spinner-bis" role="status" hidden={!isLoading}/>
                        </div>
                    </td>
                </tr>
            )))}
            </tbody>
        </table>
    );
}

export default SessionsTable;