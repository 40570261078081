import React, {ChangeEvent, FC, useEffect, useState} from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./Inscrits.css";
import {localiseDateString} from "../../helpers/processInput";
import {handleErrorCode, makeRequest} from "../../helpers/makeRequests";
import {ReturnTypes} from "../../interfaces/returntype";
import {Sidebar} from "../../components/Sidebar";
import {Link, useLocation} from "react-router-dom";

interface Inscrit {
    id: string;
    name: string;
    firstName: string;
    email: string;
    civility: string;
    phone: string;
    birthDate: string;
    nickName: string;
    address: string;
    addressLine2: string;
    nationality: string;
    city: string;
    country: string;
    postalCode: string;
    jobArea: string;
    paymentMethod: string;
    Notes: string;
    registrationTimestamp: string;
    sessionId: string;
    paymentStatus: string;
}

const Inscrits: FC = () => {

    const [inscrits, setInscrits] = useState<Inscrit[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isDanger, setIsDanger] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const location = useLocation();

    const fetchInscrits = async () => {
        makeRequest("get-registrations", "", ReturnTypes.Json).then((response) => {
            const result: Inscrit[] = handleErrorCode(response);
            setInscrits(result ? result : []);
        }).catch((error) => {
            setIsDanger(true);
            console.error(error);
        }).finally(() => {
            setIsLoaded(true);
        });
    }

    useEffect(() => {
        fetchInscrits();
    }, []);

    const tableToCSV = () => {
        const csv_data = [];
        const rows = document.getElementsByTagName('tr');
        for (let i = 0; i < rows.length; i++) {
            const cols = rows[i].querySelectorAll('td,th');
            const csvrow = [];
            for (let j = 0; j < cols.length; j++) {
                const colElement = cols[j].innerHTML;
                if (colElement.includes("<select")) {
                    const dropdownMenuCellContainer = cols[j] as HTMLTableDataCellElement;
                    const dropdownMenu = dropdownMenuCellContainer.querySelector("select") as HTMLSelectElement;
                    csvrow.push(dropdownMenu.value);
                } else {
                    csvrow.push(cols[j].innerHTML);
                }
            }
            csv_data.push(csvrow.join(";"));
        }
        const final_csv_data = csv_data.join('\n');
        downloadCSVFile(final_csv_data);
    }

    const formatAddress = (inscrit: Inscrit): string => {
        let address: string;
        if (inscrit.addressLine2) {
            address = `${inscrit.address}, ${inscrit.addressLine2}, ${inscrit.city}, ${inscrit.postalCode}, ${inscrit.country}`;
        } else {
            address = `${inscrit.address}, ${inscrit.city}, ${inscrit.postalCode}, ${inscrit.country}`;
        }
        return address;
    }

    const onSelectPaymentStatus = (event: ChangeEvent<HTMLSelectElement>) => {
        setHasChanges(true);
        const optionValue: string = event.target.value;
        const userId: string = event.target.id;
        const inscrit: Inscrit = inscrits.find((elem) => elem.id === userId) as Inscrit;
        inscrit.paymentStatus = optionValue;
        const updatedInscrits = inscrits.map(existingInscrit =>
            existingInscrit.id === inscrit.id ? inscrit : existingInscrit
        );
        setInscrits(updatedInscrits);
    }

    const uploadChanges = async () => {
        setIsLoaded(false);
        try {
            const response = await makeRequest("update-registration", JSON.stringify({"registrations": inscrits}), ReturnTypes.String);
            handleErrorCode(response);
            setIsLoaded(true);
            setIsDanger(false);
            setHasChanges(false);
            fetchInscrits();
            setIsSuccess(true);
        } catch (e) {
            console.error(e);
            setIsDanger(true);
        }
    }

    function downloadCSVFile(csv_data: string) {
        const csvFile = new Blob([csv_data], {type: "text/csv"});
        const temp_link = document.createElement('a');
        temp_link.download = "Tableau d'inscrits.csv";
        const url = window.URL.createObjectURL(csvFile);
        temp_link.href = url;

        temp_link.style.display = "none";
        document.body.appendChild(temp_link);
        temp_link.click();
        document.body.removeChild(temp_link);
    }

    return (
        <div className="container-fluid container-bis" style={{overflowY: "hidden"}}>
            <div className="row flex-nowrap">
                <Sidebar/>
                <div className="col py-3" style={{backgroundColor: "#fffaed"}}>
                    <div className="main-table table-overflow">
                        <table id="inscritsTableau" className="table table-striped table-responsive table-bis">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Civilité</th>
                                <th scope="col">Prénom</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Date de naissance</th>
                                <th scope="col">Adresse e-mail</th>
                                <th scope="col">Numéro de téléphone</th>
                                <th scope="col">Adresse</th>
                                <th scope="col">Nationalité</th>
                                <th scope="col">Profession</th>
                                <th scope="col">Moyen de paiement</th>
                                <th scope="col">Formation choisie</th>
                                <th scope="col">Notes</th>
                                <th scope="col">Date d'inscription</th>
                                <th scope="col">Statut du paiement</th>
                            </tr>
                            </thead>
                            <tbody>
                            {inscrits.length > 0 && inscrits.map((user, index) => (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{user.civility}</td>
                                    <td>{user.firstName}</td>
                                    <td>{user.name}</td>
                                    <td>{localiseDateString(user.birthDate)}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phone}</td>
                                    <td>{formatAddress(user)}</td>
                                    <td>{user.nationality}</td>
                                    <td>{user.jobArea}</td>
                                    <td>{user.paymentMethod}</td>
                                    <td>{user.nickName}</td>
                                    <td>{user.Notes}</td>
                                    <td>{localiseDateString(user.registrationTimestamp, true)}</td>
                                    <td><select id={user.id} onChange={onSelectPaymentStatus}
                                                value={user.paymentStatus}>
                                        <option>En attente</option>
                                        <option>En cours</option>
                                        <option>Complété</option>
                                    </select></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="spinner-bis">
                        <div id="loading" className="spinner-border spinner-bis" role="status" hidden={isLoaded}/>
                    </div>
                    <div className="alert alert-danger" role="alert" hidden={!isDanger}>
                        Impossible de compléter cette requête pour le moment. Veuillez vous reconnecter <Link
                        to="/login" state={{from: location}}>ici</Link>
                    </div>
                    <div className="alert alert-success" role="alert" hidden={!isSuccess}>
                        Mise à jour effectuée avec succès.
                    </div>
                    <div style={{marginTop: "20px", marginBottom: "10px", display: "flex", gap: "1rem"}}
                         hidden={!isLoaded}>
                        <button type="button" className="btn btn-secondary" onClick={tableToCSV}>Exporter en CSV
                        </button>
                        <button type="button" className="btn btn-secondary gap-2 d-flex" disabled={!hasChanges}
                                onClick={uploadChanges}>
                            <i className="bi bi-floppy"></i>
                            Enregistrer
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default Inscrits;