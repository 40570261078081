import React, {FC} from "react";

interface CarouselItemProps {
    url: string;
    alt?: string;
    isActive?: boolean;
    objectPositionOverride?: string;
    isVideo?: boolean;
    mediaType?: string;
}

const CarouselItem: FC<CarouselItemProps> = (props: CarouselItemProps) => {
    return (
        (<div className={props.isActive ? "carousel-item active" : "carousel-item"}>
            {!props.isVideo ?
                <img src={props.url}
                     className="d-block w-100"
                     style={{
                         height: "calc(65vh)",
                         objectFit: "cover",
                         objectPosition: props.objectPositionOverride ?? "50% 50%",
                     }}
                     alt={props.alt ?? "Groupe d'industriels au cours d'une formation RINVINDAF"}/>
                : (
                    <video controls data-umami-event="Play video">
                        <source src={props.url} type={props.mediaType ?? "video/mp4"}/>
                    </video>)}
        </div>)
    )
}

export default CarouselItem;