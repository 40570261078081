import React, {FC, useEffect} from "react";
import "./Header.css"

interface HeaderProps {
    currentPage: string;
}

const Header: FC<HeaderProps> = ({currentPage}) => {

    useEffect(() => {
        const handleScroll = () => {
            const headerElement: HTMLElement = document.getElementById("headerElem") as HTMLElement;
            if (headerElement != null) {
                const sticky = headerElement.offsetTop;

                if (window.scrollY > sticky) {
                    headerElement.classList.add("sticky");
                } else {
                    headerElement.classList.remove("sticky");
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const accueilElement: HTMLElement = document.getElementById("accueil-bouton") as HTMLElement;
        const inscriptionsElement: HTMLElement = document.getElementById("inscriptions-bouton") as HTMLElement;
        if (currentPage === "accueil") {
            accueilElement.classList.add("disabled");
            inscriptionsElement.classList.remove("disabled");
        } else if (currentPage === "inscriptions") {
            inscriptionsElement.classList.add("disabled");
            accueilElement.classList.remove("disabled");
        }
    }, []);

    return (
        <nav data-testid="navbar" className="navbar navbar-expand-lg bg-light header-container" id="headerElem">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">IEG Education</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a id="accueil-bouton" className="nav-link active" aria-current="page" href="/">Accueil</a>
                        </li>
                        <li className="nav-item">
                            <a id="inscriptions-bouton" className="nav-link" href="/inscription">Inscriptions</a>
                        </li>
                        <li className="nav-item">
                            <a id="dates-bouton" className="nav-link" href="/#prochaines-dates">Dates des prochaines
                                formations</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Header;