import React, { createContext, Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeRequestForStatusCode } from "../../helpers/makeRequests";

interface SessionContextType {
    isAuthenticated: boolean;
    isLoginSuccessful: boolean;
    login: (username: string, password: string) => void;
    logout: () => void;
}

const SessionContext = createContext<SessionContextType | undefined>(undefined);

const SessionContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoginSuccessful, setIsLoginSuccessful] = useState<boolean>(true);
    const navigate = useNavigate();

    const login = async (username: string, password: string) => {
        const authenticationStatus: number = await makeRequestForStatusCode("signin", JSON.stringify({ "username": username, "password": password }))
        if (authenticationStatus === 200) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
            setIsLoginSuccessful(false);
            console.log("invalid credentials");
        }
    }

    const logout = () => {
        setIsAuthenticated(false);
        navigate("/login");
    }

    return (
        <SessionContext.Provider value={{ isAuthenticated, isLoginSuccessful, login, logout }}>
            {children}
        </SessionContext.Provider>
    );
}

export default SessionContextProvider;

export const useSession = () => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error('useSession must be used within a SessionContextProvider');
    }
    return context;
};