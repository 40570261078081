import React, { FC } from "react";
import "./Success.css";

const Success: FC = () => {

    return (
        <div className="p-3 md-4 border rounded-3 container">
            <div className="check-container">
                <i className="bi bi-check-lg h1"></i>
                <h1>Votre candidature a été enregistrée.</h1>
                <h5>Vous serez recontacté(e) dans les prochains jours pour confirmer vos détails.</h5>
            </div>

        </div>
    )


}

export default Success;