import React, {FC} from "react";
import {Route, Routes} from "react-router-dom";
import {Home} from "../pages/Home";
import {Inscrits} from "../pages/Inscrits";
import {Sessions} from "../pages/Sessions";
import LoginPage from "../pages/LoginPage/LoginPage";
import SessionContextProvider from "../components/SessionContextProvider/SessionContextProvider";
import {ProtectedRoute} from "../components/ProtectedRoute";
import {Success} from "../pages/Success";
import {FormulaireInscription} from "../pages/FormulaireInscription";
import {Tariffs} from "../pages/Tariffs";

export const PageHandler: FC = () => {
    return (
        <SessionContextProvider>
            <Routes>
                <Route path="/">
                    <Route index element={<Home/>}></Route>
                </Route>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/inscription" element={<FormulaireInscription/>}/>
                <Route path="/admin" element={
                    <ProtectedRoute>
                        <Inscrits/>
                    </ProtectedRoute>
                }/>
                <Route path="/inscrits" element={
                    <ProtectedRoute>
                        <Inscrits/>
                    </ProtectedRoute>
                }/>
                <Route path="/sessions" element={
                    <ProtectedRoute>
                        <Sessions/>
                    </ProtectedRoute>
                }/>
                <Route path="/success" element={<Success/>}/>
                <Route path="/tariffs" element={<Tariffs/>}/>
            </Routes>
        </SessionContextProvider>

    )
};
