import React, {FC, useEffect, useState} from "react";
import {AddressAutocompleteBar} from "../AddressAutocompleteBar";
import {Map} from "@vis.gl/react-google-maps";
import {MapHandler} from "../MapHandler";
import "./ExpandableRow.css";
import {SaveButton} from "../SaveButton";
import {handleErrorCode, makeRequest, makeRequestTemp} from "../../helpers/makeRequests";
import {Position} from "../../interfaces/position";
import {ReturnTypes} from "../../interfaces/returntype";

interface ExpandableRowProps {
    sessionId: number;
}

const ExpandableRow: FC<ExpandableRowProps> = ({sessionId}) => {
    const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
    const [selectedPosition, setSelectedPosition] = useState<Position>({
        id: 0,
        place_name: "Bafang",
        geo_position: {latitude: 5.1589179039001465, longitude: 10.187328338623047}
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isDanger, setIsDanger] = useState<boolean>(false);

    const uploadChanges = async () => {
        setIsLoading(true);
        const body = JSON.stringify({
            place: selectedPlace.name,
            longitude: selectedPlace.geometry.location.lng(),
            latitude: selectedPlace.geometry.location.lat(),
            session_id: sessionId
        });
        const statusCode = await makeRequestTemp("helpers/save-position", body);
        if (statusCode === 200) {
            setIsDanger(false);
            setIsSuccess(true);
        } else {
            setIsSuccess(false);
            setIsDanger(true);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (selectedPlace) {
            const position: Position = {
                id: 0,
                place_name: selectedPlace.name, geo_position:
                    {
                        latitude: selectedPlace.geometry.location.lat(),
                        longitude: selectedPlace.geometry.location.lng()
                    }
            };
            setSelectedPosition(position);
        }
    }, [selectedPlace]);

    useEffect(() => {
        const getPosition = async () => {
            const body = JSON.stringify({sessionId});
            const response = await makeRequest("get-position", body, ReturnTypes.Json, "helpers");
            try {
                const position: Position = handleErrorCode(response);
                console.log("position", position);
                setSelectedPosition(position);
            } catch (e) {
                console.error(e);
            }
        }
        getPosition();
    }, []);
    return (
        <div style={{display: "block", flexDirection: "row", gap: "0.5rem"}}>
            <AddressAutocompleteBar onPlaceSelect={setSelectedPlace}/>
            <div className="mapstyle gap-between-elems">
                <Map
                    defaultZoom={14}
                    defaultCenter={{
                        lat: selectedPosition.geo_position.latitude,
                        lng: selectedPosition.geo_position.longitude
                    }}
                    streetViewControl={false}
                    zoomControl={false}>
                </Map>
                <MapHandler place={selectedPosition}/>
            </div>
            <SaveButton onClick={uploadChanges} hasChanges={selectedPlace != null}/>
            <div id="loading" className="spinner-border spinner-bis gap-between-elems" role="status"
                 hidden={!isLoading}/>
            <div className="alert alert-success" role="alert" hidden={!isSuccess}>
                Mise à jour effectuée avec succès.
            </div>
            <div className="alert alert-danger" role="alert" hidden={!isDanger}>
                Une erreur est survenue. Veuillez réessayer ultérieurement.
            </div>
        </div>
    )
};

export default ExpandableRow;